import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useSelector } from "react-redux";
// import {
//   Portlet,
//   PortletBody,
//   PortletHeader,
//   PortletHeaderToolbar
// } from "../../partials/content/Portlet";
// import { metronic } from "../../../_metronic";
import { toAbsoluteUrl } from "../../../_metronic/utils/utils";
import DashboardReport from "../reports/dashboard";
import { Col, Row } from "reactstrap";
import { translations } from "../../../translate";

let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

export default function Dashboard() {
  const [isTechAdmin, setIsTechAdmin] = useState(0);
  const LEVEL8DATA = [
    {
      title: Strings["Bills requests"],
      bullet: "dot",
      page: "PosBillsRequests",
      translate: "MENU.DASHBOARD",
      display:
        (localStorage.getItem("USER_LEVEL") == 3 &&
          localStorage.getItem("ACCOUNT_TYPE") == 7) ||
        localStorage.getItem("USER_LEVEL") == 8
          ? "1"
          : "0",
    },
    {
      title: Strings["Balance Recharge Requests"],
      bullet: "dot",
      page: "PosBalanceRequests",
      translate: "MENU.DASHBOARD",
      display:
        (localStorage.getItem("USER_LEVEL") == 3 &&
          localStorage.getItem("ACCOUNT_TYPE") == 7) ||
        localStorage.getItem("USER_LEVEL") == 8
          ? "1"
          : "0",
    },
    {
      title: Strings["pay"],
      bullet: "dot",
      page: "PayManagement",
      translate: "MENU.DASHBOARD",
      display:
        (localStorage.getItem("USER_LEVEL") == 3 &&
          localStorage.getItem("ACCOUNT_TYPE") == 7) ||
        localStorage.getItem("USER_LEVEL") == 8
          ? "1"
          : "0",
    },
    {
      title: Strings["Receivables"],
      bullet: "dot",
      page: "Receivables",
      display: "1",
    },
    {
      title: Strings["debitFlow"],
      bullet: "dot",
      page: "DebitStatus",
      display: "1",
    },
    {
      title: Strings["ReceivablesFlow"],
      bullet: "dot",
      page: "ReceivablesFlow",
      display: "1",
    },
  ];
  const LEVEL9DATA = [
    {
      title: Strings["Receivables"],
      bullet: "dot",
      page: "Receivables",
      display: "1",
    },
    {
      title: Strings["Employees"],
      bullet: "dot",
      page: "posEmp/1",
      display: "1",
    },
  ];
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      localStorage.getItem("PERM_TYPE") == 5 ||
      localStorage.getItem("POS_MGR_EMP") == 1
    ) {
      getUserBalance();
    } else if (localStorage.getItem("USER_LEVEL") == 1) {
      is_tech_admin();
    }
  }, []);
  const getUserBalance = () => {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getUserBalance",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            localStorage.setItem("USER_BALANCE_FROM", "redux");
            // console.log("call distpatch");
            dispatch({
              type: "UPDATEBALANCE",
              payload: data?.balanceCurr ? data?.balanceCurr : "0",
            });
          }
        }
      });
  };
  const is_tech_admin = () => {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "is_tech_admin",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            // console.log(data);
            setIsTechAdmin(data["isMain"]);
          }
        }
      });
  };
  return (
    <div>
      {localStorage.getItem("PERM_TYPE") == 2 ||
      localStorage.getItem("PERM_TYPE") == 5 ||
      (localStorage.getItem("USER_LEVEL") == 1 &&
        (isTechAdmin == true || isTechAdmin == 1)) ? (
        <div>
          <DashboardReport />
        </div>
      ) : localStorage.getItem("POS_MGR_EMP_NEW") == 1 ||
        localStorage.getItem("USER_LEVEL") == 18 ? (
        <Row>
          {LEVEL8DATA.map(({ title, page }, i) => (
            <Col key={i} md={3} lg={3} sm={12} xs={12}>
              <div
                onClick={(event) => (window.location = page)}
                className="pos-main-profile-section-box main-color-1"
              >
                <div></div>
                <div>{title}</div>
              </div>
            </Col>
          ))}
        </Row>
      ) : localStorage.getItem("USER_LEVEL") == 19 ? (
        <Row>
          {LEVEL9DATA.map(({ title, page }, i) => (
            <Col key={i} md={3} lg={3} sm={12} xs={12}>
              <div
                onClick={(event) => (window.location = page)}
                className="pos-main-profile-section-box main-color-1"
              >
                <div></div>
                <div>{title}</div>
              </div>
            </Col>
          ))}
        </Row>
      ) : (
        <div className="dashboard-main-view">
          <img
            className="img-fluid dashboard-logo"
            alt="logo"
            src={toAbsoluteUrl(
              "/media/ezpay/EZPAY_LOGO" + window.MAIN_IMAGE + ".png"
            )}
          />
          <p className="dashboard-main-title">CRM & System</p>
        </div>
      )}
    </div>
  );
}
